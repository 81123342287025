<template>
  <header class="navbar">
    <div class="navbar-container">
      <!-- Logo -->
      <div class="logo">
        <router-link to="/">
          <img src="/locums_medicine_logo.png" alt="Locums Medicine Logo" />
        </router-link>
      </div>

      <!-- Hamburger Icon -->
      <div class="hamburger" @click="toggleMenu">
        <div></div>
        <div></div>
        <div></div>
      </div>

      <!-- Navigation Links -->
      <nav :class="['nav-links', { open: isMenuOpen }]">
        <ul>
          <li><router-link to="/" class="nav-item">Home</router-link></li>
          <li><router-link to="/jobs" class="nav-item">Physician Jobs</router-link></li>
          <li><router-link to="/locum-tenens" class="nav-item">Locum Tenens</router-link></li>
          <li><router-link to="/contact" class="nav-item">Contact</router-link></li>
        </ul>
      </nav>
    </div>

    <!-- Fullscreen Overlay for Mobile Navigation -->
    <div v-if="isMenuOpen" class="mobile-nav-overlay">
      <!-- Close Button -->
      <button class="close-button" @click="closeMenu">&times;</button>
      <ul>
        <li><router-link to="/" @click="closeMenu" class="nav-item">Home</router-link></li>
        <li><router-link to="/jobs" @click="closeMenu" class="nav-item">Physician Jobs</router-link></li>
        <li><router-link to="/locum-tenens" @click="closeMenu" class="nav-item">Locum Tenens</router-link></li>
        <li><router-link to="/contact" @click="closeMenu" class="nav-item">Contact</router-link></li>
      </ul>
    </div>
  </header>
</template>


<script>
export default {
  name: "NavBar",
  data() {
    return {
      isMenuOpen: false, // Toggle state for hamburger menu
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen; // Toggles menu visibility
    },
    closeMenu() {
      this.isMenuOpen = false; // Closes menu when a link is clicked
    },
  },
};
</script>

<style scoped>
/* Navbar */
.navbar {
  background-color: white;
  border-bottom: 1px solid #e5e5e5;
  padding: 1rem 2rem;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
}

.logo img {
  width: 100px;
  height: auto;
}

.nav-links ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 2rem;
  flex-wrap: nowrap;
}

.nav-item {
  text-decoration: none;
  color: #1d1d1d;
  font-weight: bold;
  transition: color 0.3s ease;
}

.nav-item:hover {
  color: red;
}

/* Hamburger Icon */
.hamburger {
  display: none;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
}

.hamburger div {
  width: 25px;
  height: 3px;
  background-color: #1d1d1d;
}

/* Mobile Fullscreen Overlay */
.mobile-nav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.mobile-nav-overlay ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.mobile-nav-overlay li {
  margin: 1.5rem 0;
}

.mobile-nav-overlay .nav-item {
  font-size: 1.5rem;
  font-weight: bold;
  color: #1d1d1d;
  text-decoration: none;
  transition: color 0.3s ease;
}

.mobile-nav-overlay .nav-item:hover {
  color: red;
}

.close-button {
  position: absolute; 
  top: 1rem;         
  right: 1.5rem;     
  background: none;
  border: none;
  font-size: 2rem;
  font-weight: bold;
  color: #1d1d1d;
  cursor: pointer;
  z-index: 10001; 
}

.close-button:hover {
  color: red;
}

/* Responsive Navbar */
@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .hamburger {
    display: flex;
  }

  .logo img {
    width: 70px; /* Smaller width for mobile view */
  }
}
</style>
