<template>
  <div>
    <Navbar />
    <router-view :key="$route.fullPath" />
    <Footer />
  </div>
</template>

<script>
import Navbar from './components/NavBar.vue';
import Footer from './components/AppFooter.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
  },
};
</script>
