<template>
  <div class="physician-jobs">
    <!-- Header Section -->
    <div class="header-section">
      <h1>Physician jobs</h1>
      <p>
        Explore top physician job opportunities in various locations. Fresh job
        postings are added daily, so be sure to visit regularly.
      </p>
    </div>

    <!-- Job Search Filters -->
    <div class="job-filters">
      <select v-model="selectedJobType" class="dropdown">
        <option value="" disabled selected>Job type</option>
        <option value="locum-tenens">Locum Tenens</option>
        <option value="telehealth">Telehealth</option>
      </select>

      <select v-model="selectedPhysician" class="dropdown">
        <option value="" disabled selected>Physician</option>
        <option value="general">General</option>
        <option value="specialist">Specialist</option>
      </select>

      <select v-model="selectedSpecialty" class="dropdown">
        <option value="" disabled selected>Specialty</option>
        <option value="cardiology">Cardiology</option>
        <option value="radiology">Radiology</option>
        <option value="pediatrics">Pediatrics</option>
        <option value="infectiousdisease">Infectious Disease</option>
      </select>

      <select v-model="selectedLocation" class="dropdown">
        <option value="" disabled selected>Location</option>
        <option value="new-york">New York</option>
        <option value="california">California</option>
        <option value="texas">Texas</option>
      </select>

      <select v-model="selectedDatePosted" class="dropdown">
        <option value="" disabled selected>Date posted</option>
        <option value="last-24-hours">Last 24 hours</option>
        <option value="last-week">Last week</option>
        <option value="last-month">Last month</option>
      </select>

      <select v-model="selectedWorkSetting" class="dropdown">
        <option value="" disabled selected>Work setting</option>
        <option value="onsite">Onsite</option>
        <option value="remote">Remote</option>
        <option value="hybrid">Hybrid</option>
      </select>
    </div>

    <!-- Job Display Section -->
    <div class="job-display">
      <!-- Left Column: Job List -->
      <div class="job-list">
        <div
          class="job-item"
          v-for="job in jobs"
          :key="job.id"
          @click="selectJob(job)"
          :class="{ active: selectedJob && selectedJob.id === job.id }"
        >
          <h4 class="job-title">{{ job.title }}</h4>
          <p class="job-location">{{ job.location }}</p>
          <div class="job-info">
            <ul>
              <li v-for="(desc, index) in job.shortDescription" :key="index">{{ desc }}</li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Right Column: Job Details -->
      <div class="job-details" v-if="selectedJob">
        <h3>{{ selectedJob.title }}</h3>
        <p>{{ selectedJob.meta }}</p>
        <h4>Job quick facts</h4>
        <div class="job-info">
  <ul class="job-info-column">
    <li v-for="(fact, index) in selectedJob.quickFactsLeft" :key="index">{{ fact }}</li>
  </ul>
  <ul class="job-info-column">
    <li v-for="(fact, index) in selectedJob.quickFactsRight" :key="index">{{ fact }}</li>
  </ul>
</div>

<h4>Benefits of LocumsMedicine</h4>
<div class="job-info">
  <ul class="job-info-column">
    <li v-for="(benefit, index) in selectedJob.benefitsLeft" :key="index">{{ benefit }}</li>
  </ul>
  <ul class="job-info-column">
    <li v-for="(benefit, index) in selectedJob.benefitsRight" :key="index">{{ benefit }}</li>
  </ul>
</div>

        <h4>Description</h4>
        <p>{{ selectedJob.description }}</p>

                <!-- Interested? Form Section -->
                <div ref="formSection" class="interested-form">
          <h4>Interested?</h4>
          <p>Complete the form and a consultant will help you get started.</p>
          <form>
            <div class="form-group">
              <label for="firstName">First name</label>
              <input type="text" id="firstName" placeholder="First name" />
            </div>
            <div class="form-group">
              <label for="lastName">Last name</label>
              <input type="text" id="lastName" placeholder="Last name" />
            </div>
            <div class="form-group">
              <label for="phone">Phone</label>
              <input type="phone" id="phone" placeholder="Phone" />
            </div>
            <div class="form-group">
              <label for="email">Email</label>
              <input type="email" id="email" placeholder="Email" />
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Papa from "papaparse";

export default {
  name: "PhysicianJobs",
  data() {
    return {
      jobs: [],
      selectedJob: null,
      selectedJobType: "",
      selectedLocation: "",
      selectedPhysician: "",
      selectedSpecialty: "",
      selectedDatePosted: "",
      selectedWorkSetting: "",
    };
  },
  created() {
    this.fetchJobs();
  },
  methods: {
  async fetchJobs() {
    const sheetURL =
      "https://docs.google.com/spreadsheets/d/e/2PACX-1vSzAEnItBQCrmZS62j0mJ5MNYgAcNTcUhYUalUwK1yFodb4vACaUJj1MzPZIkAf5Q/pub?output=csv";
    try {
      const response = await axios.get(sheetURL);
      const parsedData = Papa.parse(response.data, {
        header: true,
        skipEmptyLines: true,
      });

      // Map CSV data to the job structure
      this.jobs = parsedData.data.map((row, index) => ({
        id: index + 1,
        title: row.Title || "Untitled Job",
        location: row.Location || "Unknown Location",
        shortDescription: row["Short Description"] ? row["Short Description"].split(";") : [],
        quickFactsLeft: row["Quick Facts Left"] ? row["Quick Facts Left"].split(";") : [],
        quickFactsRight: row["Quick Facts Right"] ? row["Quick Facts Right"].split(";") : [],
        benefitsLeft: row["Benefits Left"] ? row["Benefits Left"].split(";") : [],
        benefitsRight: row["Benefits Right"] ? row["Benefits Right"].split(";") : [],
        meta: row.Meta || "",
        description: row.Description || "No description available.",
      }));

      // Set the first job as the default selected job
      this.selectedJob = this.jobs.length > 0 ? this.jobs[0] : null;
    } catch (error) {
      console.error("Error fetching jobs from Google Sheets:", error);
    }
  },

      // Add the selectJob method here
      selectJob(job) {
      this.selectedJob = job;
    },
},
};
</script>

<style scoped>
/* Page Container */
.physician-jobs {
  padding: 2rem;
  background-color: #f5f8f9;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Header Section */
.header-section {
  text-align: left;
  margin-bottom: 1.5rem; 
  width: 100%;
  max-width: 1100px;
}

.header-section h1 {
  font-size: 2rem;
  font-weight: bold;
  color: #1d1d1d;
  margin-bottom: 0.5rem;
}

.header-section p {
  font-size: 1rem;
  color: #6c757d;
  margin: 0;
  line-height: 1.5;
}

/* Job Filters Section */
.job-filters {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  flex-wrap: nowrap; /* Prevent wrapping on desktop */
  margin-bottom: 1rem;
  padding: 0 1rem;
}

/* Dropdown Styles */
.dropdown {
  width: 180px; /* Keep consistent width */
  max-width: 180px;
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
}

.dropdown:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.dropdown option {
  font-size: 1rem;
  color: #495057;
}

/* Job Display Section */
.job-display {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  max-width: 1100px;
  margin: 2rem auto 0;
  width: 100%;
  min-height: 75vh;
  box-sizing: border-box; 
}


/* Left Column: Job List */
.job-list {
  flex: 1;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  overflow: hidden;
  height: 400px;
  overflow-y: auto;
  min-height: 75vh;
}

.job-item {
  padding: 1rem;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
}

.job-item:last-child {
  border-bottom: none;
}

.job-item:hover {
  background-color: #f9f9f9;
}

.job-item.active {
  border-left: 4px solid #007bff;
  background-color: #eef6ff;
}

/* Job Info - Displaying Two Columns */
.job-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Allow columns to wrap on smaller screens */
}

.job-info-column {
  width: 45%; /* Two columns, each with 45% width */
  list-style-type: disc;
  padding-left: 20px;
  font-size: 1rem;
  color: #495057;
}

.job-info-column li {
  margin-bottom: 0.5rem;
}

/* Right Column: Job Details */
.job-details {
  flex: 2;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 1.5rem;
  height: 400px; /* Match the height of the job list */
  overflow-y: auto; /* Scrollable if content overflows */
  word-wrap: break-word; /* Prevent text overflow */
  overflow-wrap: break-word; /* Ensure long words wrap properly */
  box-sizing: border-box;
  min-height: 75vh;
}

.job-details h3 {
  margin: 0 0 1rem;
  color: #d32f2f;
}

.job-meta-actions {
  display: flex;
  justify-content: space-between; /* Space between text and buttons */
  align-items: center; /* Align vertically */
  margin-bottom: 1rem; /* Space below the row */
}

.job-meta {
  margin: 0;
  font-size: 0.85rem; /* Subtle size for meta text */
  color: #6c757d;
}

.job-requirements {
  list-style-type: none;
  padding: 0;
  margin: 0 0 1rem;
}

.job-requirements li {
  margin-bottom: 0.5rem;
}

.job-actions {
  display: flex;
  gap: 0.5rem; /* Add spacing between buttons */
}

.save-button,
.share-button,
.apply-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
}

/* Button Colors */
.save-button {
  background-color: #f1f1f1;
  color: #6c757d;
  border: 1px solid #d6d8da;
}

.share-button {
  background-color: #f1f1f1;
  color: #6c757d;
  border: 1px solid #d6d8da;
}

.apply-button {
  background-color: #d32f2f;
  color: white;
}

.interested-form {
  margin-top: 2rem;
  padding: 1.5rem;
  background-color: #f5f8f9;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
  box-sizing: border-box;
}

/* Form group styling */
.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
}

.form-group input {
  width: 100%;
  padding: 0.75rem;
  margin-top: 0.25rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
}

/* Button styling */
button {
  padding: 0.75rem;
  background-color: #d32f2f;
  color: white;
  border: none;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
}

button:hover {
  background-color: #c62828;
}

button:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

form {
  display: flex;
  flex-direction: column;
}

form p {
  font-size: 0.85rem;
  color: #777;
  margin-top: 1rem;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .job-filters {
    flex-wrap: wrap; /* Allow filters to wrap on mobile */
    justify-content: center;
  }

  .dropdown {
    flex: 1 1 calc(50% - 1rem); /* Two filters per row */
    max-width: calc(50% - 1rem);
  }
  
  .job-display {
    flex-direction: column;
    gap: 1rem;
  }

  .job-list,
  .job-details {
    height: auto;
    width: 100%;
  }

  .interested-form {
    margin: 1rem auto; 
    padding: 1rem;
    max-width: 90%;
    box-shadow: none;
  }

  .form-group input {
    font-size: 0.9rem;
  }

  button {
    font-size: 0.9rem;
    padding: 0.5rem;
  }

  .header-section h1 {
    font-size: 1.5rem;
  }

  .header-section p {
    font-size: 0.9rem;
  }

  .job-item .job-title {
    font-size: 1rem;
  }

  .job-details h3 {
    font-size: 1.25rem;
  }
}
</style>
