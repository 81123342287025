<template>
  <div class="locum-overview">
    <div class="locum-content">
      <div class="text-content">
          <h3>Locum Tenens Overview</h3>
        <h2>Discover the Freedom of Locum Tenens</h2>
        <p>
          Embrace the freedom and flexibility that comes with locum tenens work. Whether you're looking for short-term assignments or long-term opportunities, we offer the perfect balance between your professional and personal life. Explore how locums can give you the freedom to choose where and when you work.
        </p>
        <button @click="goToJobSearch" class="search-jobs-button">Search Jobs</button>
      </div>
      <div class="image-content">
        <img src="locumstenens.jpg" alt="Locum Tenens Image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LocumTenensOverview',
  methods: {
    goToJobSearch() {
      this.$router.push('/jobs'); // Navigates to the jobs page
    }
  }
};
</script>

<style scoped>
/* Page container to center content horizontally and align at the top */
.locum-overview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem;
  background-color: #f5f8f9;
  min-height: 100vh;
}

/* Flex container for the content */
.locum-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  gap: 2rem;
}

/* Text content styling */
.text-content {
  flex: 1;
  text-align: left;
}

.text-content h2 {
  font-size: 2rem;
  color: #1d1d1d;
  margin-bottom: 1rem;
}

.text-content p {
  font-size: 1.1rem;
  color: #6c757d;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

/* Red button styling */
.search-jobs-button {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  background-color: #d32f2f; 
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
}

.search-jobs-button:hover {
  background-color: #c2185b; 
}

/* Image content styling */
.image-content img {
  max-width: 600px;
  height: auto;
  border-radius: 8px;
  margin: 0 auto; 
  display: block; 
}
/* Mobile Responsive Design */
@media (max-width: 768px) {
  .locum-content {
    flex-direction: column;
    text-align: center;
  }

  .text-content h2 {
    font-size: 1.5rem;
  }

  .text-content p {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .search-jobs-button {
    font-size: 0.9rem;
    padding: 0.6rem 1rem;
  }

  .image-content img {
    max-width: 400px;
    border-radius: 4px;
  }
}

@media (max-width: 480px) {
  .locum-overview {
    padding: 1rem;
  }

  .text-content h2 {
    font-size: 1.3rem;
  }

  .text-content p {
    font-size: 0.9rem
  }

  .search-jobs-button {
    font-size: 0.8rem;
    padding: 0.5rem 0.8rem;
  }

  .image-content img {
    max-width: 300px; 
  }
}
</style>
