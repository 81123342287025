import { createRouter, createWebHistory } from "vue-router";
import PhysicianJobs from "@/components/PhysicianJobs.vue";
import LocumTenens from "@/components/LocumTenens.vue";
import About from "@/components/About.vue";
import ContactUs from "@/components/ContactUs.vue";

const routes = [
  { path: "/", name: "Home", component: About },
  // { path: "/about", name: "About", component: About },
  { path: "/jobs", name: "PhysicianJobs", component: PhysicianJobs },
  { path: "/locum-tenens", name: "LocumTenens", component: LocumTenens },
  { path: "/contact", name: "ContactUs", component: ContactUs },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
