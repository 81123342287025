<template>
  <div class="contact-page">
    <!-- Header Section -->
    <div class="header-section">
      <div v-if="notification" class="notification">
      {{ notification }}
    </div>
      <h1>Contact us</h1>
      <p>Complete the form and a consultant will reach out shortly.</p>
    </div>

    <!-- Content Section -->
    <div class="content-section">
      <div class="contact-form">
        <form @submit.prevent="handleSubmit">
          <div class="form-group">
            <label for="firstName">First name</label>
            <input
              type="text"
              id="firstName"
              v-model="form.firstName"
              placeholder="First name"
              required
            />
          </div>
          <div class="form-group">
            <label for="lastName">Last name</label>
            <input
              type="text"
              id="lastName"
              v-model="form.lastName"
              placeholder="Last name"
              required
            />
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input
              type="email"
              id="email"
              v-model="form.email"
              placeholder="Email"
              required
            />
          </div>
          <div class="form-group">
            <label for="phone">Phone</label>
            <input
            type="tel"
            id="phone"
            v-model="form.phone"
            placeholder="Phone"
            @input="validatePhone"
            />
          </div>
          <div class="form-group">
            <label for="profession">Profession</label>
            <select id="profession" v-model="form.profession" required>
              <option value="" disabled>Select your profession</option>
              <option value="doctor">Doctor</option>
              <option value="nurse">Nurse</option>
              <option value="technician">Technician</option>
            </select>
          </div>
          <div class="form-group">
            <label for="specialty">Specialty</label>
            <select id="specialty" v-model="form.specialty" required>
              <option value="" disabled>Select your specialty</option>
              <option value="cardiology">Cardiology</option>
              <option value="radiology">Radiology</option>
              <option value="pediatrics">Pediatrics</option>
            </select>
          </div>
          <div class="form-group">
            <label for="comments">Comments</label>
            <textarea
              id="comments"
              v-model="form.comments"
              placeholder="Enter your comments"
            ></textarea>
          </div>
          <div class="form-group checkbox-group">
            <input
              type="checkbox"
              id="agree"
              v-model="form.agreed"
              required
            />
            <label for="agree">
              By submitting your information, you agree that you may be contacted by a member of our team via SMS or Email.
            </label>
          </div>
          <div class="submit-container">
            <button type="submit" class="submit-button" :disabled="loading">
              <div class="button-content">
                <span v-if="loading">
                  Submitting...
                  <span class="spinner"></span>
                </span>
                <span v-else>Submit</span>
              </div>
            </button>
</div>

        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        profession: "",
        specialty: "",
        comments: "",
        agreed: false,
      },
      loading: false,
      notification: "",
    };
  },

methods: {
  async handleSubmit() {
    this.loading = true; 
    this.notification = ""; 
    try {
      await axios.post("https://locumsmedicine-contact-form-backend.onrender.com/send", this.form);

      this.resetForm();
      this.loading = false;

      this.notification = "Form submitted successfully! Someone will get in touch with you soon.";
      setTimeout(() => (this.notification = ""), 3000); 
    } catch (error) {
      console.error("Error submitting form:", error.message || error);

      this.loading = false;

      this.notification = "Failed to submit the form. Please try again.";
      setTimeout(() => (this.notification = ""), 3000);
    }
  },

  resetForm() {
    this.form = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      profession: "",
      specialty: "",
      comments: "",
      agreed: false,
    };
  },

  validatePhone() {
    this.form.phone = this.form.phone.replace(/[^0-9]/g, "");
  },
},
};
</script>

<style scoped>
.contact-page {
  padding: 2rem;
  max-width: 1000px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
}

.header-section {
  text-align: left;
  margin-bottom: 2rem;
}

.header-section h1 {
  font-size: 2rem;
  color: #333;
  font-weight: bold;
}

.header-section p {
  color: #666;
  margin-top: 0.5rem;
}

.contact-form {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #555;
  font-weight: bold;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-group textarea {
  height: 120px;
  resize: none;
}

.checkbox-group {
  display: flex;
  align-items: flex-start;
}

.checkbox-group input {
  margin-right: 0.5rem;
  margin-top: 0.1rem;
}

.submit-container {
  display: flex;
  align-items: center; 
  justify-content: center;
  gap: 10px;
}

.button-content {
  display: flex;
  align-items: center; /* Perfectly align spinner and text vertically */
  justify-content: center; /* Center content inside the button */
  gap: 8px; /* Space between text and spinner */
  line-height: 1; /* Prevent any text shifting due to line-height */
}


.submit-button {
  background-color: #d32f2f;
  color: white;
  border: none;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  text-transform: uppercase;
  font-weight: bold;
  display: inline-flex; /* Allows flexible alignment */
  align-items: center; /* Align spinner and text vertically */
  justify-content: center; 
}

.submit-button:hover {
  background-color: #c62828;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.spinner {
  display: inline-block; /* Ensure spinner is inline with text */
  border: 4px solid rgba(0, 0, 0, 0.1); /* Light grey background border */
  border-top: 4px solid #ffffff; /* Spinner color */
  border-radius: 50%; /* Makes it a perfect circle */
  width: 16px; /* Compact spinner size */
  height: 16px;
  animation: spin 0.8s linear infinite; /* Smooth spinning effect */
  vertical-align: middle; /* Align spinner with text baseline */
}

.notification {
  position: fixed; 
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #dff0d8;
  color: #3c763d;
  padding: 1rem;
  border: 1px solid #d6e9c6;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-size: 1rem;
  text-align: center;
  max-width: 90%;
  word-wrap: break-word;
}

.notification.error {
  background-color: #f2dede;
  color: #a94442;
  border-color: #ebccd1;
}

.privacy-note {
  margin-top: 1.5rem;
  font-size: 0.85rem;
  color: #666;
  text-align: center;
}

.privacy-note a {
  color: #007bff;
  text-decoration: none;
}

.privacy-note a:hover {
  text-decoration: underline;
}

.checkbox-group {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.checkbox-group input[type="checkbox"] {
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
}

.checkbox-group label {
  color: #333;
  font-size: 0.9rem;
  line-height: 1.5;
  margin: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
