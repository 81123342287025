<template>
  <div class="page-wrapper">
    <footer>
      <p>&copy; 2024 LocumsMedicine. All rights reserved.</p>
      <p>
        Need assistance? Email us at
        <a href="mailto:info@locumsmedicine.com" class="contact-email">
          info@locumsmedicine.com
        </a>
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'AppFooter'
};
</script>

<style scoped>
html, body {
  margin: 0;
  padding: 0;
  height: 100%; 
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

footer {
  background-color: #f2f3f4;
  color: #333;
  text-align: center;
  padding: 1rem 0;
  font-size: 0.9rem;
  margin-top: auto;
}

footer a.contact-email {
  color: #e74c3c; 
  text-decoration: none;
  font-weight: bold;
}

footer a.contact-email:hover {
  text-decoration: underline;
}


</style>
