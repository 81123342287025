<template>
  <div class="about-page">
    <div class="banner-section">
      <div class="banner-content">
        <div class="text-content">
          <h2>Our Expertise in Staffing Solutions</h2>
          <p>
            As expert consultants and industry leaders in locums, we provide staffing solutions to meet your needs today and prepare you for tomorrow.
          </p>
        </div>

        <div class="image-content">
          <div class="image-wrapper">
            <div v-if="loading.banner" class="spinner"></div>
            <img
              src="/banner.jpg"
              alt="Staffing Solutions"
              @load="imageLoaded('banner')"
              @error="imageError('banner')"
            />
          </div>
        </div>
      </div>
    </div>

    <section class="content-section">
      <div class="content-wrapper">
        <div class="text-content">
          <h2>Our Mission</h2>
          <p>
            At LocumsMedicine, our mission is to ensure that healthcare facilities and providers can deliver seamless, high-quality care to patients without interruptions. We strive to create opportunities that connect passionate healthcare professionals with organizations that need their expertise.
          </p>
          <p>
            By streamlining the staffing process, we focus on reducing downtime, filling critical positions efficiently, and improving patient outcomes. Our partnerships are built on trust, transparency, and a deep understanding of the healthcare industry.
          </p>
          <p>
            We believe in empowering providers to achieve flexibility, fulfillment, and growth in their careers while ensuring that hospitals, clinics, and other facilities have the resources to deliver exceptional care. Together, we make a difference in healthcare delivery across communities.
          </p>
        </div>

        <div class="image-content">
          <div class="image-wrapper">
            <div v-if="loading.mission" class="spinner"></div>
            <img
              src="/mission.jpg"
              alt="Our Mission"
              @load="imageLoaded('mission')"
              @error="imageError('mission')"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="content-section reverse">
      <div class="content-wrapper">
        <div class="image-content">
          <div class="image-wrapper">
            <div v-if="loading.whyUs" class="spinner"></div>
            <img
              src="/whyus.jpg"
              alt="Our Team"
              @load="imageLoaded('whyUs')"
              @error="imageError('whyUs')"
            />
          </div>
        </div>
        <div class="text-content">
          <h2>Why Choose Us</h2>
          <p>
            At LocumsMedicine, we are not just a staffing company, we are your partner in healthcare excellence. With years of experience in the locum tenens industry, we understand the unique challenges faced by healthcare professionals and facilities alike.
          </p>
          <p>
            Our personalized approach sets us apart. We work closely with providers to understand their goals, preferences, and expertise, ensuring each placement is a perfect fit. For facilities, we offer customized solutions that align with their staffing needs, budget, and timelines.
          </p>
          <p>
            By prioritizing relationships and quality over quantity, we ensure that every healthcare professional feels valued and supported. From flexible work opportunities to career-boosting experiences, we help providers grow while facilities maintain continuity in patient care.
          </p>
          <p>
            Choose LocumsMedicine for our commitment to excellence, our nationwide network of healthcare professionals, and our dedication to making every placement impactful—for professionals, organizations, and the patients they serve.
          </p>

        </div>
      </div>
    </section>
    <button @click="goToJobSearch" class="search-jobs-button">Search Jobs</button>
  </div>
</template>


  <script>
export default {
  data() {
    return {
      loading: {
        banner: true,
        mission: true,
        whyUs: true,
      },
    };
  },
  methods: {
    goToJobSearch() {
      this.$router.push('/jobs'); // Navigates to the jobs page
    },

    imageLoaded(imageKey) {
  this.loading[imageKey] = false;
  const imageElement = document.querySelector(`img[alt="Our Team"]`);
  if (imageElement) {
    imageElement.classList.add("loaded");
  } else {
    console.error(`Image element for ${imageKey} not found!`);
  }
},

imageError(imageKey) {
  this.loading[imageKey] = false; // Stop spinner even if the image fails
  console.error(`Failed to load image: ${imageKey}`);
},
},
};
  </script>
  
  <style scoped>
.about-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: Arial, sans-serif;
  color: #333;
}

section {
  flex: 1; /* Allows sections to grow and fill the available space */
}
/* Hero Section */
.hero-section {
  background: url("https://via.placeholder.com/1920x600") no-repeat center center;
  background-size: cover;
  text-align: center;
  color: white;
  padding: 6rem 1rem;
}

.hero-content h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.hero-content p {
  font-size: 1.25rem;
  max-width: 600px;
  margin: 0 auto;
}

/* Content Sections */
.content-section {
  flex: 1; /* Fill remaining space */
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4rem 2rem;
  background-color: #f9f9f9;
}

.content-section.reverse {
  background-color: #fff;
  flex-direction: row-reverse;
}

.content-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.text-content {
  flex: 1;
  padding: 1rem;
}

.text-content h2 {
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.text-content p {
  font-size: 1.1rem;
  line-height: 1.6;
}

.image-content {
  flex: 1;
  display: flex;
  justify-content: center;
}

.image-content img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.banner-section {
  background: linear-gradient(to right, #e60033, #d3002f);
  color: white;
  padding: 3rem 1.5rem;
  overflow: hidden;
}

.banner-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.banner-content .text-content {
  flex: 1;
  padding: 1rem;
  z-index: 2;
}

.banner-content .image-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-content img {
  width: 100%;
  max-width: 500px;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.image-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}

.image-wrapper img {
  opacity: 1;
}
.image-wrapper img.loaded {
  opacity: 1; 
}


.spinner {
  position: absolute;
  width: 30px;
  height: 30px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #e60033;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.search-jobs-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #f44336;
  color: white;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.search-jobs-button:hover {
  background-color: #d32f2f;
  transform: scale(1.05);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@media (max-width: 768px) {
  .banner-content,
  .content-wrapper {
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 2rem 1rem;
  }

  .text-content {
    margin-bottom: 1.5rem;
  }

  /* Image adjustments for mobile */
  .image-content {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .image-content img {
    width: 90%; 
    max-width: 400px; 
    height: auto;
    margin-top: 1rem;
    border-radius: 8px;
  }

  .content-section.reverse {
    flex-direction: column; 
  }
}



  </style>